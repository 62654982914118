<template>
  <div class="d-flex align-center justify-center fill-height">
    <unAuthorized/>
    <errorNetwork/>

    <!-- ==== LOADING LOGIN OTOMATIS ==== -->
    <div class="text-center" v-show="loading">
      <v-progress-circular
        :size="100"
        color="#d31145"
        indeterminate>
      </v-progress-circular>
      <div class="black--text mt-8">Memproses login otomatis ...</div>
    </div>

    <!-- ==== ERROR LOGIN OTOMATIS ==== -->
    <div  v-if="errorMessage"  class="py-9">
      <v-img src="@/assets/images/404/404.png"
        max-height="500"
        max-width="500"
        class="ma-auto">
      </v-img>
    </div>
    <div v-if="errorMessage">
      <p class="title text-first font-weight-bold mb-1">
        {{errorMessage}}
      </p>
      <p class="caption text-second">
        Silahkan klik tombol di bawah untuk kembali ke Login
      </p>
      <v-btn
        small
        light
        width="150"
        color="#263238"
        elevation="3"
        class="white--text text-capitalize caption"
        @click="toLogin">
        <v-icon small class="mr-1">mdi-chevron-left-circle-outline</v-icon>
        Kembali Ke Login
      </v-btn>
    </div>
  </div>
</template>

<script>
import { TokenService } from "@/service/Storage.Service";
import { post, get } from "@/service/Axios";
import unAuthorized from "@/components/401.vue";
import errorNetwork from "@/components/500.vue";
export default {
  data: () => ({
    loading: false,
    errorMessage: ""
  }),
  components: {
    unAuthorized,
    errorNetwork
  },
  mounted(){
    this.autoLogin()
  },
  methods: {
    toLogin(){
      TokenService.removeToken()
      window.location = '/login'
    },
    async autoLogin(){
      this.loading = true
      let data = this.$router.currentRoute.query.data
      let signature = this.$router.currentRoute.query.signature

      if (data && signature) {
        data = data.trim().split(" ").join("+");
        signature = signature.trim().split(" ").join("+");

        await post(`internal/dashboard/franchise/login`, {
          data: {
            data,
            signature,
          },
        }).then( async (response) => {
          let res = response.data;

          switch (res.status) {
            case 200:

              TokenService.saveToken(
                res.results.token,
                JSON.stringify(res.results.data)
              );
              await get(`franchise/store`)
              .then((response) => {
                let res = response.data;
                  if (res.status === 200) {
                    localStorage.setItem("user_store", JSON.stringify(res.results.data));
                    localStorage.setItem("selected_store", JSON.stringify(res.results.data[0]));
                    localStorage.setItem("selected_store_id", 0);
                    window.location = "/dashboard"
                  }else {
                    this.process.run = false;
                    this.error.message = res.message;
                  }
                })
              window.location = "/"
              break;
            case 400:
              this.errorMessage = res.message;
              break;

          }
          this.loading = false
        }).catch( error => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true
              this.process.run = false
              this.process.form = false
            }
          }else{
            // this.$store.state.errorNetwork = true
            this.process.run = false
            this.process.form = false
          }
        })
      }else{
        this.errorMessage = "Data yang dikirimkan tidak sesuai"
      }
    }
  }
}
</script>

<style scoped>
  *{
    margin: 0;
    padding: 0;
  }

  .loading-screen{
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading{
    width: 80px;
    display: flex;
    flex-wrap: wrap;
    animation: rotate 3s linear infinite;
  }

  .text{
    top: 70%;
    padding-left: 20px;
  }

  @keyframes rotate{
    to{
      transform: rotate(360deg);
    }
  }

  .loading span{
    width: 32px;
    height: 32px;
    background-color: red;
    margin: 4px;
    animation: scale 1.5s linear infinite;
  }

  @keyframes scale{
    50%{
      transform: scale(1.2);
    }
  }

  .loading span:nth-child(1){
    border-radius: 50% 50% 0 50%;
    background-color: #e77f67;
    transform-origin: bottom right;
  }

  .loading span:nth-child(2){
    border-radius: 50% 50% 50% 0;
    background-color: #778beb;
    transform-origin: bottom left;
    animation-delay: .5s;
  }

  .loading span:nth-child(3){
    border-radius: 50% 0 50% 50%;
    background-color: #f8a5c2;
    transform-origin: top right;
    animation-delay: 1.5s;
  }

  .loading span:nth-child(4){
    border-radius: 0 50% 50% 50%;
    background-color: #f5cd79;
    transform-origin: top left;
    animation-delay: 1s;
  }
</style>